<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />

        <div>
        <Toolbar class="mb-2">
          <template #start>
            <Button
              @click="go_to"
              icon="pi pi-fw pi-arrow-circle-left"
              class="p-button-rounded p-button-info mr-2" 
            />
            <Dropdown
                
                  id="status"
                  v-model="submissionstatus"
                  :options="submissionItems"
                  :value="submissionItems.value"
                  optionLabel="name"
                  placeholder="Select Year"
                  class="my-select mr-2"
                  required="true"
            ></Dropdown>
          
              
            <Dropdown
                  
                  id="status"
                  v-model="coursestatus"
                  :options="courseItems"
                  :value="courseItems.value"
                  optionLabel="name"
                  placeholder="Select Cource"
                 class="my-select mr-2"
                  required="true"
                  v-on:change="get_list"
            ></Dropdown>
          </template>

          <template #end>
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
        </div>


       
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
           :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Download Student Registration</b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
          <Column
            header="First Name"
            :field="columns[1]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.firsT_NAME == '' ||
                  data.firsT_NAME == null ||
                  data.firsT_NAME == undefined
                "
                >-</span
              >
              <span v-else>{{ data.firsT_NAME }}</span>
            </template>
          </Column>

          <Column
            header="Middle Name"
            :field="columns[2]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.middeL_NAME == '' ||
                  data.middeL_NAME == null ||
                  data.middeL_NAME == undefined
                "
                >-</span
              >
              <span v-else>{{ data.middeL_NAME }}</span>
            </template>
          </Column>
          <Column
            header="Surname"
            :field="columns[3]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.surname == '' ||
                  data.surname == null ||
                  data.surname == undefined
                "
                >-</span
              >
              <span v-else>{{ data.surname }}</span>
            </template>
          </Column>
          <Column
            header="State"
            :field="columns[5]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.state == '' ||
                  data.state == null ||
                  data.state == undefined
                "
                >-</span
              >
              <span v-else>{{ data.state }}</span>
            </template>
          </Column>
          <Column
            header="District"
            :field="columns[7]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.disrtictname == '' ||
                  data.disrtictname == null ||
                  data.disrtictname == undefined
                "
                >-</span
              >
              <span v-else>{{ data.disrtictname }}</span>
            </template>
          </Column>
          <Column
            header="Taluka"
            :field="columns[8]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.taluka == '' ||
                  data.taluka == null ||
                  data.taluka == undefined
                "
                >-</span
              >
              <span v-else>{{ data.taluka }}</span>
            </template>
          </Column>
          <Column
            header="City/Village"
            :field="columns[9]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.citY_VILLAGE == '' ||
                  data.citY_VILLAGE == null ||
                  data.citY_VILLAGE == undefined
                "
                >-</span
              >
              <span v-else>{{ data.citY_VILLAGE }}</span>
            </template>
          </Column>
          <Column
            header="Mobile"
            :field="columns[10]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.mobile == '' ||
                  data.mobile == null ||
                  data.mobile == undefined
                "
                >-</span
              >
              <span v-else>{{ data.mobile }}</span>
            </template>
          </Column>
          <Column
            header="Email"
            :field="columns[11]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.email == '' ||
                  data.email == null ||
                  data.email == undefined
                "
                >-</span
              >
              <span v-else>{{ data.email }}</span>
            </template>
          </Column>
          <Column
            header="Password"
            :field="columns[12]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.password == '' ||
                  data.password == null ||
                  data.password == undefined
                "
                >-</span
              >
              <span v-else>{{ data.password }}</span>
            </template>
          </Column>
          <Column
            header="Pincode"
            :field="columns[13]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.pincode == '' ||
                  data.pincode == null ||
                  data.pincode == undefined
                "
                >-</span
              >
              <span v-else>{{ data.pincode }}</span>
            </template>
          </Column>
          <Column
            header="Adhar Card"
            :field="columns[14]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.aadhaR_CARD == '' ||
                  data.aadhaR_CARD == null ||
                  data.aadhaR_CARD == undefined
                "
                >-</span
              >
              <span v-else>{{ data.aadhaR_CARD }}</span>
            </template>
          </Column>
          <Column
            header="Status"
            :field="columns[15]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.status == '' ||
                  data.status == null ||
                  data.status == undefined
                "
                >-</span
              >
              <span v-else>{{ data.status }}</span>
            </template>
          </Column>
          <Column
            header="Cource"
            :field="columns[17]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.cource == '' ||
                  data.cource == null ||
                  data.cource == undefined
                "
                >-</span
              >
              <span v-else>{{ data.cource }}</span>
            </template>
          </Column>
          <Column
            header="Enrollment No"
            :field="columns[18]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.enrollmenT_NO == '' ||
                  data.enrollmenT_NO == null ||
                  data.enrollmenT_NO == undefined
                "
                >-</span
              >
              <span v-else>{{ data.enrollmenT_NO }}</span>
            </template>
          </Column>
          <Column
            header="Created On"
            :field="columns[19]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.createD_ON == '' ||
                  data.createD_ON == null ||
                  data.createD_ON == undefined
                "
                >-</span
              >
              <span v-else>{{ data.createD_ON }}</span>
            </template>
          </Column>
          <Column
            header="Created By"
            :field="columns[20]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.createD_BY == '' ||
                  data.createD_BY == null ||
                  data.createD_BY == undefined
                "
                >-</span
              >
              <span v-else>{{ data.createD_BY }}</span>
            </template>
          </Column>
          <Column
            header="Updated On"
            :field="columns[21]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.updateD_ON == '' ||
                  data.updateD_ON == null ||
                  data.updateD_ON == undefined
                "
                >-</span
              >
              <span v-else>{{ data.updateD_ON }}</span>
            </template>
          </Column>
          <Column
            header="Updated By"
            :field="columns[22]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.updateD_BY == '' ||
                  data.updateD_BY == null ||
                  data.updateD_BY == undefined
                "
                >-</span
              >
              <span v-else>{{ data.updateD_BY }}</span>
            </template>
          </Column>
          <Column
            header="Year"
            :field="columns[23]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.year == '' ||
                  data.year == null ||
                  data.year == undefined
                "
                >-</span
              >
              <span v-else>{{ data.year }}</span>
            </template>
          </Column>
          <Column
            header="Gender"
            :field="columns[24]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.gender == '' ||
                  data.gender == null ||
                  data.gender == undefined
                "
                >-</span
              >
              <span v-else>{{ data.gender }}</span>
            </template>
          </Column>
          <Column
            header="Category"
            :field="columns[25]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.category == '' ||
                  data.category == null ||
                  data.category == undefined
                "
                >-</span
              >
              <span v-else>{{ data.category }}</span>
            </template>
          </Column>
          <Column
            header="Cast"
            :field="columns[26]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.caste == '' ||
                  data.caste == null ||
                  data.caste == undefined
                "
                >-</span
              >
              <span v-else>{{ data.caste }}</span>
            </template>
          </Column>
          <Column
            header="Family Income"
            :field="columns[27]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.familY_INCOME == '' ||
                  data.familY_INCOME == null ||
                  data.familY_INCOME == undefined
                "
                >-</span
              >
              <span v-else>{{ data.familY_INCOME }}</span>
            </template>
          </Column>
          <Column
            header="Medium"
            :field="columns[28]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.medium == '' ||
                  data.medium == null ||
                  data.medium == undefined
                "
                >-</span
              >
              <span v-else>{{ data.medium }}</span>
            </template>
          </Column>
          <Column
            header="Board"
            :field="columns[29]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.board == '' ||
                  data.board == null ||
                  data.board == undefined
                "
                >-</span
              >
              <span v-else>{{ data.board }}</span>
            </template>
          </Column>
          <Column
            header="Percentage"
            :field="columns[30]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.percentage == '' ||
                  data.percentage == null ||
                  data.percentage == undefined
                "
                >-</span
              >
              <span v-else>{{ data.percentage }}</span>
            </template>
          </Column>
          <Column
            header="Area"
            :field="columns[31]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.area == '' ||
                  data.area == null ||
                  data.area == undefined
                "
                >-</span
              >
              <span v-else>{{ data.area }}</span>
            </template>
          </Column>
          <Column
            header="School District"
            :field="columns[32]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.schooL_DISTRICT == '' ||
                  data.schooL_DISTRICT == null ||
                  data.schooL_DISTRICT == undefined
                "
                >-</span
              >
              <span v-else>{{ data.schooL_DISTRICT }}</span>
            </template>
          </Column>
          <Column
            header="dob"
            :field="columns[33]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.dob == '' ||
                  data.dob == null ||
                  data.dob == undefined
                "
                >-</span
              >
              <span v-else>{{ data.dob }}</span>
            </template>
          </Column>
          <Column
            header="Appl. Status"
            :field="columns[34]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.applicatioN_STATUS == '' ||
                  data.applicatioN_STATUS == null ||
                  data.applicatioN_STATUS == undefined
                "
                >-</span
              >
              <span v-else>{{ data.applicatioN_STATUS }}</span>
            </template>
          </Column>
          <Column
            header="Application Status By"
            :field="columns[35]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.applicatioN_STATUS_BY == '' ||
                  data.applicatioN_STATUS_BY == null ||
                  data.applicatioN_STATUS_BY == undefined
                "
                >-</span
              >
              <span v-else>{{ data.applicatioN_STATUS_BY }}</span>
            </template>
          </Column>
          <Column
            header="Application Status By PH"
            :field="columns[36]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.applicatioN_STATUS_BY_PH == '' ||
                  data.applicatioN_STATUS_BY_PH == null ||
                  data.applicatioN_STATUS_BY_PH == undefined
                "
                >-</span
              >
              <span v-else>{{ data.applicatioN_STATUS_BY_PH }}</span>
            </template>
          </Column>
          <Column
            header="Application Status On"
            :field="columns[37]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.applicatioN_STATUS_ON == '' ||
                  data.applicatioN_STATUS_ON == null ||
                  data.applicatioN_STATUS_ON == undefined
                "
                >-</span
              >
              <span v-else>{{ data.applicatioN_STATUS_ON }}</span>
            </template>
          </Column>
          <Column
            header="Height"
            :field="columns[38]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.height == '' ||
                  data.height == null ||
                  data.height == undefined
                "
                >-</span
              >
              <span v-else>{{ data.height }}</span>
            </template>
          </Column>
          <Column
            header="Normal Chest"
            :field="columns[39]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.normaL_CHEST == '' ||
                  data.normaL_CHEST == null ||
                  data.normaL_CHEST == undefined
                "
                >-</span
              >
              <span v-else>{{ data.normaL_CHEST }}</span>
            </template>
          </Column>
          <Column
            header="Pumped Chest"
            :field="columns[40]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.pumpeD_CHEST == '' ||
                  data.pumpeD_CHEST == null ||
                  data.pumpeD_CHEST == undefined
                "
                >-</span
              >
              <span v-else>{{ data.pumpeD_CHEST }}</span>
            </template>
          </Column>
          <Column
            header="Creamylayer"
            :field="columns[41]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.creamylayer == '' ||
                  data.creamylayer == null ||
                  data.creamylayer == undefined
                "
                >-</span
              >
              <span v-else>{{ data.creamylayer }}</span>
            </template>
          </Column>
          <Column
            header="Dummy ID"
            :field="columns[42]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.dummY_ID == '' ||
                  data.dummY_ID == null ||
                  data.dummY_ID == undefined
                "
                >-</span
              >
              <span v-else>{{ data.dummY_ID }}</span>
            </template>
          </Column>
          
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Teacher Remark On Student Answer"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="studenT_ID">Student ID</label>
                <InputText
                  id="studenT_ID"
                  v-model.trim="product.studenT_ID"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !product.studenT_ID,
                  }"
                />
              </div>
              <div class="field">
                <label for="studentName">Student Name</label>
                <InputText
                  id="studentName"
                  v-model.trim="product.studentName"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !product.studentName,
                  }"
                />
              </div>

              <div class="field">
                <label for="status">Check By Teacher</label>
                <Dropdown
                  id="status"
                  v-model="selectstatus"
                  :options="dropdownItems"
                  :value="dropdownItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
              </div>

              <div class="field">
                <label for="teacheR_REMARK">Teacher Remark</label>
                <Textarea
                  id="teacheR_REMARK"
                  v-model.trim="product.teacheR_REMARK"
                  required="true"
                  autofocus
                  rows="5"
                  :class="{
                    'p-invalid': submitted && !product.teacheR_REMARK,
                  }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Updatesubmission"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },
      courseItems: [],
      coursestatus: { name: "", value: "" },
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentsm: "",
      file_attachmentdpp: "",
      file_attachmentdppA: "",
      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],

      stdproducts: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
    this.get_course_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    go_to()
    {
      this.$router.push('/teacherreport');
    },
    view_video(video) {
      // alert(video)
      window.open(video.data.embeD_LINK, "_blank");
    },
    view_std(info) {
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_course_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseItems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseItems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },

    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadsm() {
      this.file_attachmentsm = this.$refs.file_attachmentsm.files[0];

      //alert(this.$refs.file_attachmentsm.files[0].name);
    },
    handleFileUploaddpp() {
      this.file_attachmentdpp = this.$refs.file_attachmentdpp.files[0];

      //alert(this.$refs.file_attachmentdpp.files[0].name);
    },
    handleFileUploaddppA() {
      this.file_attachmentdppA = this.$refs.file_attachmentdppA.files[0];

      //alert(this.$refs.file_attachmentdppA.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (this.submissionstatus.value && this.coursestatus.value) {
        var data = {
          Limit: this.limit,
          page_no: this.page_no,
          batch: this.coursestatus.value,
          year: this.submissionstatus.value,
          count: true,
        };
        this.loading = true;
        var promise = apis.studentlis(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      if (this.submissionstatus.value && this.coursestatus.value) {
        var data = {
          Limit: this.limit,
          page_no: this.page_no,
          batch: this.coursestatus.value,
          year: this.submissionstatus.value,
          count: false,
        };
        this.loading = true;
        var promise = apis.studentlis(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Updatesubmission() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please select check by teacher",
          life: 3000,
        });
        return false;
      }
      if (!this.product.teacheR_REMARK) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.tscH_DE_ID) {
        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          STUDENT_ID: this.product.studenT_ID,
          TEACHER_REMARK: this.product.teacheR_REMARK,
          TEACHER_CHECK: this.selectstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.updateteacherremark(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachmentsm = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
.my-select {
 min-width: 12em;
}
</style>
